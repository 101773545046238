import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'report.create',
    component: () => import('./views/pages/Create.vue'),
    children: [
      {
        path: 'report/:id/:containerId',
        name: 'report.show',
        component: () => import('./views/modals/Show.vue'),
        meta: {
          title: 'Object',
        },
      },
    ],
    meta: {
      title: 'Melden',
    },
  },
];
