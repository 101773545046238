import { UseMapbox } from '@libero/mapbox/hooks/useMapbox';
import { GEOLOCATION_SOURCE } from '@libero/mapbox/types/sources';
import { featureCollection, point } from '@turf/turf';
import { GeoJSONSource } from 'mapbox-gl';
import { ref } from 'vue';

type Callback = () => void;

export interface UseGeolocationSource {
  handleCoordinates: (coordinates?: [number, number]) => void;
  onLoad: (callback: Callback) => void;
}

export const useGeolocationSource = (mapbox: UseMapbox): UseGeolocationSource => {
  const callbacks = ref<Callback[]>([]);

  const createSource = () => {
    mapbox.map.addSource(GEOLOCATION_SOURCE, {
      type: 'geojson',
      data: featureCollection([]),
    });

    callbacks.value.forEach((callback) => callback());
  };

  const handleCoordinates = (coordinates?: [number, number]) => {
    if (!coordinates) return;

    const source = mapbox.map.getSource(GEOLOCATION_SOURCE) as GeoJSONSource;
    source?.setData(featureCollection([point(coordinates)]));

    mapbox.map.flyTo({ center: coordinates, zoom: 18 });
  };

  const onLoad = (callback: Callback) => {
    callbacks.value.push(callback);
  };

  mapbox.onStyleLoad(createSource);

  return {
    handleCoordinates,
    onLoad,
  };
};
