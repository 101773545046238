import { UseGeoJsonSource } from '@libero/mapbox/hooks/sources/useGeoJsonSource';
import { UseSelectionTool } from '@libero/mapbox/hooks/tools/useSelectionTool';
import { UseMapbox } from '@libero/mapbox/hooks/useMapbox';
import {
  ACTIVE_LINE_LAYER,
  BLANK_LINE_LAYER,
  LINE_LAYER,
  NEW_LINE_LAYER,
} from '@libero/mapbox/types/layers';
import {
  BLANK_GEOJSON_SOURCE,
  DRAWING_SOURCE,
  GEOJSON_SHAPE_SOURCE,
} from '@libero/mapbox/types/sources';
import { Color } from '@libero/types/Color';
import { computed, watch } from 'vue';

export const useLineLayer = (
  mapbox: UseMapbox,
  geoJsonSource: UseGeoJsonSource,
  selectionTool?: UseSelectionTool,
  color?: Color,
): void => {
  const registerLayers = () => {
    if (mapbox.map.getSource(BLANK_GEOJSON_SOURCE)) {
      mapbox.map.addLayer({
        id: BLANK_LINE_LAYER,
        type: 'line',
        source: BLANK_GEOJSON_SOURCE,
        minzoom: 16,
        paint: {
          'line-color': Color.gray050,
          'line-width': ['interpolate', ['linear'], ['zoom'], 11, 0.25, 25, 8],
        },
        filter: ['any', ['==', '$type', 'LineString'], ['==', '$type', 'Polygon']],
      });
    }

    mapbox.map.addLayer({
      id: LINE_LAYER,
      type: 'line',
      source: GEOJSON_SHAPE_SOURCE,
      minzoom: 14,
      paint: {
        'line-color': color || ['get', 'color'],
        'line-width': ['interpolate', ['linear'], ['zoom'], 11, 0.25, 25, 8],
      },
      filter: ['any', ['==', '$type', 'LineString'], ['==', '$type', 'Polygon']],
    });

    mapbox.map.addLayer({
      id: ACTIVE_LINE_LAYER,
      type: 'line',
      source: GEOJSON_SHAPE_SOURCE,
      minzoom: 14,
      paint: {
        'line-color': color || ['get', 'color'],
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          11,
          ['case', ['==', ['get', 'count'], 0], 0.35, 1.25],
          25,
          ['case', ['==', ['get', 'count'], 0], 2.5, 9],
        ],
      },
      filter: [
        'all',
        ['in', 'id', ''],
        ['any', ['==', '$type', 'LineString'], ['==', '$type', 'Polygon']],
      ],
    });

    if (mapbox.draw) {
      mapbox.map.addLayer({
        id: NEW_LINE_LAYER,
        type: 'line',
        source: DRAWING_SOURCE,
        paint: {
          'line-color': Color.primary600,
          'line-width': ['interpolate', ['linear'], ['zoom'], 9, 1, 25, 6],
        },
        filter: ['any', ['==', '$type', 'LineString'], ['==', '$type', 'Polygon']],
      });
    }
  };

  if (selectionTool) {
    const isSelectionMode = computed(() => selectionTool.isSelectionMode);

    const setSelectionPaint = () => {
      if (selectionTool.isSelectionMode) {
        mapbox.map.setPaintProperty(LINE_LAYER, 'line-color', Color.gray400);
      } else {
        mapbox.map.setPaintProperty(LINE_LAYER, 'line-color', color || ['get', 'color']);
      }
    };

    watch(isSelectionMode, setSelectionPaint);
    mapbox.onStyleLoad(setSelectionPaint);
  }

  geoJsonSource.onLoad(registerLayers);
};
