<script setup lang="ts">
import { Square3Stack3DIcon } from '@heroicons/vue/24/solid';
import { MapLayer } from '@libero/mapbox/hooks/tools/useLayers';
import { MapStyle } from '@libero/mapbox/hooks/tools/useStyle';
import { SelectValues } from '@libero/types/Select';
import Button from '@libero/ui-framework/Button/Button.vue';
import CheckboxGroup from '@libero/ui-framework/Checkbox/CheckboxGroup.vue';
import Dropdown from '@libero/ui-framework/Dropdown/Dropdown.vue';
import Select from '@libero/ui-framework/Select/Select.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Tooltip from '@libero/ui-framework/Tooltip/Tooltip.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { useI18n } from 'vue-i18n';

interface Props {
  style: MapStyle;
  layers: string[];
  layersOptions: MapLayer[];
  onUpdateStyle: (value: MapStyle) => void;
  onUpdateLayers: (value: string[]) => void;
}

const props = defineProps<Props>();

const { t } = useI18n();

const handleUpdateStyle = (value: SelectValues) => {
  props.onUpdateStyle((Array.isArray(value) ? value[0] : value) as MapStyle);
};

const handleUpdateLayers = (value: (string | number)[] | null) => {
  props.onUpdateLayers(value?.map((v) => v.toString()) ?? []);
};
</script>

<template>
  <Dropdown placement="top-end" shouldStayOpen destroyOnClose>
    <template #trigger>
      <Tooltip :content="t('map.layers')">
        <Button size="sm" appearance="select">
          <template #icon>
            <Square3Stack3DIcon />
          </template>
        </Button>
      </Tooltip>
    </template>

    <template #content>
      <Stack class="layers-content">
        <Stack :gap="0.5">
          <Typography type="heading" size="sm" bold>
            {{ t('map.style') }}
          </Typography>

          <Select
            id="map-style"
            name="map-style"
            :value="style"
            width="14rem"
            :options="
              Object.values(MapStyle).map((value) => ({
                label: t(`map.style-options.${value}`),
                value,
              }))
            "
            :onUpdate="handleUpdateStyle"
          />
        </Stack>

        <Stack :gap="0.5">
          <Typography type="heading" size="sm" bold>
            {{ t('map.layers') }}
          </Typography>

          <CheckboxGroup
            id="map-layers"
            name="map-layers"
            :value="layers"
            :options="
              layersOptions.map((layer) => ({
                label: layer.title,
                value: layer.id,
              }))
            "
            :onUpdate="handleUpdateLayers"
          />
        </Stack>
      </Stack>
    </template>
  </Dropdown>
</template>

<style lang="scss" scoped>
@import '@libero/mapbox/components/Layers/Layers.scss';
</style>
