import { createI18n } from 'vue-i18n';

import nlGlobal from './global/nl.json';

const translationImports = import.meta.glob('./!(global)/nl.json', {
  eager: true,
  import: 'default',
});

const nlModules = Object.entries(translationImports).reduce<Record<string, unknown>>(
  (accumulator, [file, translations]) => {
    const key = file.split('/')[1];

    return {
      ...accumulator,
      [key]: translations,
    };
  },
  {},
);

export const i18n = createI18n({
  legacy: false,
  locale: 'nl',
  messages: {
    nl: {
      ...nlGlobal,
      ...nlModules,
    },
  },
});
