import AppLayout from '@libero/citizen-report/modules/app/views/layouts/AppLayout/AppLayout.vue';
import { useTenantStore } from '@libero/hooks/store/useTenantStore';
import { i18n } from '@libero/i18n/index';
import { defaultRouterOptions } from '@libero/utilities/routing';
import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  type RouteRecordRaw,
} from 'vue-router';

const routesImports = import.meta.glob('./modules/**/routes.ts', {
  eager: true,
}) as Record<string, { routes: RouteRecordRaw[] }>;

const routes = Object.values(routesImports)
  .map((routes) => routes.routes)
  .flat();

const fetchTenant = () => {
  const tenantStore = useTenantStore();
  if (!tenantStore.tenant) tenantStore.fetchTenant();
};

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: AppLayout,
      children: [...routes],
    },
  ],
  ...defaultRouterOptions,
});

router.beforeEach((to, from, next) => {
  fetchTenant();
  next();
});

const setTitle = (to: RouteLocationNormalized) => {
  const { tenant, onFetched } = useTenantStore();

  if (tenant) {
    const type = i18n.global.t(`tenant.types.${tenant.type}`);
    document.title = `${to.meta.title} - ${type} ${tenant.name}`;
  } else {
    onFetched(() => setTitle(to));
  }
};

router.afterEach(setTitle);
