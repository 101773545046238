import { useLocalStorage } from '@libero/hooks/useLocalStorage';
import { UseMapbox } from '@libero/mapbox/hooks/useMapbox';
import { reactive } from 'vue';

export enum MapStyle {
  Streets = 'streets',
  Outdoors = 'outdoors',
  Light = 'light',
  Dark = 'dark',
  Satellite = 'satellite',
  SatelliteStreets = 'satellite-streets',
}

const STYLES = {
  [MapStyle.Streets]: 'mapbox://styles/mapbox/streets-v12',
  [MapStyle.Outdoors]: 'mapbox://styles/mapbox/outdoors-v12',
  [MapStyle.Light]: 'mapbox://styles/mapbox/light-v11',
  [MapStyle.Dark]: 'mapbox://styles/mapbox/dark-v11',
  [MapStyle.Satellite]: 'mapbox://styles/mapbox/satellite-v9',
  [MapStyle.SatelliteStreets]: 'mapbox://styles/mapbox/satellite-streets-v12',
};

export const getMapStyle = (): string => {
  const storageStyle = localStorage.getItem('mapbox-style') as MapStyle;
  if (STYLES[storageStyle]) return STYLES[storageStyle];

  return Object.values(STYLES).at(0) as string;
};

interface UseStyle {
  style: MapStyle;
  onUpdateStyle: (value: MapStyle) => void;
}

export const useStyle = (mapbox: UseMapbox): UseStyle => {
  const [style, setStyle] = useLocalStorage<MapStyle>('mapbox-style', MapStyle.Streets);

  const onUpdateStyle = (value: MapStyle) => {
    style.value = value;
    setStyle(value);
    mapbox.map.setStyle(STYLES[value]);
  };

  return reactive({
    style,
    onUpdateStyle,
  });
};
