import { Map } from '@libero/api-client/map/types';
import { Ticket } from '@libero/api-client/ticket/types';
import { TicketSubject } from '@libero/api-client/ticket-subject/types';
import { PaginationQuery } from '@libero/api-client/types/pagination';
import { jsonApi } from '@libero/utilities/api-client';
import QueryString from 'qs';

import type { PublicTicket, StorePublicTicketRequest, SubscribeRequest } from './types';

const publicTicket = jsonApi('api/public/ticket');

const map = (query?: PaginationQuery): Promise<Map> => {
  return publicTicket.get('map', {
    searchParams: QueryString.stringify(query),
  });
};

const subject = (themeId: number): Promise<TicketSubject[]> => {
  return publicTicket.get(`subject/${themeId}`);
};

const show = (id: string | number, containerId: string | number): Promise<PublicTicket> => {
  return publicTicket.get(`${id}/${containerId}`);
};

const store = (data: StorePublicTicketRequest): Promise<Ticket> => {
  return publicTicket.post(undefined, data);
};

const subscribe =
  (id: string | number) =>
  (data: SubscribeRequest): Promise<unknown> => {
    return publicTicket.post(`${id}/subscribe`, data);
  };

export const publicTicketApi = {
  map,
  subject,
  show,
  store,
  subscribe,
};
