<script lang="ts" setup>
import { MapProperties } from '@libero/api-client/map/types';
import { PublicTicket } from '@libero/api-client/public-ticket/types';
import { Geometry } from '@libero/types/Geometry';
import Dropdown from '@libero/ui-framework/Dropdown/Dropdown.vue';
import DropdownBody from '@libero/ui-framework/Dropdown/DropdownBody.vue';
import { Feature } from 'geojson';
import { isString } from 'lodash';
import { computed } from 'vue';

import MapDropdownItem from './MapDropdownItem.vue';

interface Props {
  feature: Feature<Geometry, MapProperties>;
  onClose: () => void;
}

const props = defineProps<Props>();

const tickets = computed(() =>
  isString(props.feature.properties.tickets)
    ? (JSON.parse(props.feature.properties.tickets) as PublicTicket[])
    : undefined,
);
</script>

<template>
  <Dropdown placement="top" isAlwaysOpen :onClose="onClose">
    <template #content>
      <DropdownBody minWidth="14rem" hasNoSpacing>
        <MapDropdownItem
          v-for="ticket in tickets"
          :key="ticket.id"
          :properties="props.feature.properties"
          :ticket="ticket"
        />
      </DropdownBody>
    </template>
  </Dropdown>
</template>
