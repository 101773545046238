import { debounce as lodashDebounce, DebouncedFunc } from 'lodash';
import { onUnmounted } from 'vue';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounce = <T extends (...args: any) => any>(
  func: T,
  timeout = 300,
): DebouncedFunc<T> => {
  const debounce = lodashDebounce(func, timeout);

  onUnmounted(debounce.cancel);

  return debounce;
};
