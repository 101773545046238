<script setup lang="ts">
import { useDebounce } from '@libero/hooks/useDebounce';
import { useMutationObserver } from '@libero/hooks/useMutationObserver';
import { useResizeObserver } from '@libero/hooks/useResizeObserver';
import FadeMotion from '@libero/ui-framework/FadeMotion/FadeMotion.vue';
import { classNames } from '@libero/utilities/class-names';
import SimpleBar from 'simplebar-vue';
import { ComponentPublicInstance, computed, onMounted, ref } from 'vue';

interface Props {
  minHeight?: string;
  minWidth?: string;
  isAbsolute?: boolean;
  hasForcedTopShadow?: boolean;
  shouldHideScrollBars?: boolean;
}

withDefaults(defineProps<Props>(), {
  minHeight: undefined,
  minWidth: undefined,
});

const scrollable = ref<ComponentPublicInstance | null>(null);
const hasTopShadow = ref(false);
const hasBottomShadow = ref(false);
const hasLeftShadow = ref(false);
const hasRightShadow = ref(false);

const element = computed<HTMLElement>(
  () => scrollable.value?.$el.querySelector('.simplebar-content-wrapper'),
);

const handleShadows = useDebounce(() => {
  if (!element.value) return;

  const hasVerticalScrollBar = element.value.scrollHeight > element.value.clientHeight;
  const hasHorizontalScrollBar = element.value.scrollWidth > element.value.clientWidth;

  if (hasVerticalScrollBar) {
    const { scrollTop, offsetHeight, scrollHeight } = element.value;
    const scrolledFromTop = offsetHeight + Math.ceil(scrollTop);

    hasTopShadow.value = scrollTop !== 0;
    hasBottomShadow.value = scrolledFromTop < scrollHeight;
  }

  if (hasHorizontalScrollBar) {
    const { scrollLeft, offsetWidth, scrollWidth } = element.value;
    const scrolledFromLeft = offsetWidth + Math.ceil(scrollLeft);

    hasLeftShadow.value = scrollLeft !== 0;
    hasRightShadow.value = scrolledFromLeft < scrollWidth;
  }
});

useResizeObserver(element, handleShadows);
useMutationObserver(element, handleShadows);

onMounted(handleShadows);
</script>

<template>
  <div class="scrollable" :style="{ minHeight, minWidth }">
    <SimpleBar
      ref="scrollable"
      class="scrollable-content"
      :class="classNames({ isAbsolute, shouldHideScrollBars })"
      :onScroll="handleShadows"
      :autoHide="false"
    >
      <slot />
    </SimpleBar>

    <FadeMotion>
      <div v-if="hasForcedTopShadow || hasTopShadow" class="scrollable-top-shadow" />
    </FadeMotion>
    <FadeMotion><div v-if="hasBottomShadow" class="scrollable-bottom-shadow" /></FadeMotion>
    <FadeMotion><div v-if="hasLeftShadow" class="scrollable-left-shadow" /></FadeMotion>
    <FadeMotion><div v-if="hasRightShadow" class="scrollable-right-shadow" /></FadeMotion>
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/Scrollable/Scrollable.scss';
</style>
