import { UseGeoJsonSource } from '@libero/mapbox/hooks/sources/useGeoJsonSource';
import { UseMapbox } from '@libero/mapbox/hooks/useMapbox';
import { LABEL_LAYER } from '@libero/mapbox/types/layers';
import { BLANK_GEOJSON_SOURCE, GEOJSON_SOURCE } from '@libero/mapbox/types/sources';
import { Color } from '@libero/types/Color';

export const useLabelLayer = (mapbox: UseMapbox, geoJsonSource: UseGeoJsonSource): void => {
  const registerLayers = () => {
    [GEOJSON_SOURCE, BLANK_GEOJSON_SOURCE].forEach((source) => {
      if (mapbox.map.getSource(source)) {
        mapbox.map.addLayer({
          id: `${LABEL_LAYER}-${source}`,
          type: 'symbol',
          source,
          minzoom: 16,
          layout: {
            'text-field': ['get', 'name'],
            'text-offset': [
              'interpolate',
              ['linear'],
              ['zoom'],
              17,
              ['literal', [0, 1.75]],
              25,
              ['literal', [0, 3]],
            ],
            'text-font': ['Open Sans Bold'],
            'text-size': ['interpolate', ['linear'], ['zoom'], 17, 11, 25, 18],
          },
          paint: {
            'text-color': Color.gray600,
            'text-halo-color': Color.whiteOff,
            'text-halo-width': 3,
          },
          filter: ['==', '$type', 'Point'],
        });
      }
    });
  };

  geoJsonSource.onLoad(registerLayers);
};
