import { reactive, ref } from 'vue';

interface UseGeolocation {
  isLoading: boolean;
  onResult: (callback: OnResultCallback) => void;
  getLocation: () => void;
}

type OnResultCallback = (coordinates: [number, number]) => void;

export const useGeolocation = (): UseGeolocation => {
  const isLoading = ref(false);
  const resultCallback = ref<OnResultCallback | undefined>();

  const onResult = (callback: OnResultCallback) => {
    resultCallback.value = callback;
  };

  const handleSuccess = (position: GeolocationPosition) => {
    const { longitude, latitude } = position.coords;
    isLoading.value = false;

    resultCallback.value?.([longitude, latitude]);
  };

  const handleError = () => {
    isLoading.value = false;
  };

  const getLocation = () => {
    isLoading.value = true;
    navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
  };

  return reactive({
    isLoading,
    onResult,
    getLocation,
  });
};
