import { UseGeoJsonSource } from '@libero/mapbox/hooks/sources/useGeoJsonSource';
import { UseMapbox } from '@libero/mapbox/hooks/useMapbox';
import { CLUSTER_COUNT_LAYER, CLUSTER_LAYER } from '@libero/mapbox/types/layers';
import { GEOJSON_SOURCE } from '@libero/mapbox/types/sources';
import { Color } from '@libero/types/Color';

export const useClusterLayer = (mapbox: UseMapbox, geoJsonSource: UseGeoJsonSource): void => {
  const registerLayers = () => {
    mapbox.map.addLayer({
      id: CLUSTER_LAYER,
      type: 'circle',
      source: GEOJSON_SOURCE,
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': Color.gray800,
        'circle-radius': ['interpolate', ['linear'], ['get', 'point_count'], 20, 13, 2000, 40],
      },
    });

    mapbox.map.addLayer({
      id: CLUSTER_COUNT_LAYER,
      type: 'symbol',
      source: GEOJSON_SOURCE,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-font': ['Open Sans Bold'],
        'text-size': ['interpolate', ['linear'], ['get', 'point_count'], 20, 10, 2000, 16],
      },
      paint: {
        'text-color': Color.whiteOff,
      },
    });
  };

  geoJsonSource.onLoad(registerLayers);
};
