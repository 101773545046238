import { throttle } from 'lodash';
import { onMounted, onUnmounted, Ref, watch } from 'vue';

export const useResizeObserver = (
  reference: Ref<HTMLElement | undefined>,
  onChange: () => void,
): void => {
  const resizeObserver = new ResizeObserver(
    throttle(() => {
      if (reference.value) onChange();
    }, 50),
  );

  const register = () => {
    if (reference.value) {
      resizeObserver.observe(reference.value);
    }
  };

  const unregister = () => {
    resizeObserver.disconnect();
  };

  watch(reference, () => {
    if (reference.value) {
      register();
    } else {
      unregister();
    }
  });

  onMounted(register);
  onUnmounted(unregister);
};
