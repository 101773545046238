<script setup lang="ts">
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import { classNames } from '@libero/utilities/class-names';
import { CheckboxGroup as AntCheckboxGroup } from 'ant-design-vue';
import type { CheckboxOptionType, CheckboxValueType } from 'ant-design-vue/lib/checkbox/interface';
import { isObject } from 'lodash';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import Checkbox from './Checkbox.vue';

interface Props<T = string | number> {
  id: string;
  name: string;
  value: T[] | null;
  size?: 'sm' | 'md' | 'lg';
  options?: (string | number | CheckboxOptionType)[];
  onUpdate: (value: T[] | null) => void;
}

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
  options: () => [],
});

const { t } = useI18n();

const values = computed(() => props.value || []);

const isAllActive = computed(() =>
  props.options.every((option) =>
    values.value.includes(isObject(option) ? (option.value as string | number) : option),
  ),
);

const isIndeterminate = computed(
  () =>
    !isAllActive.value &&
    props.options.some((option) =>
      values.value.includes(isObject(option) ? (option.value as string | number) : option),
    ),
);

const handleUpdate = (value: CheckboxValueType[]) => {
  props.onUpdate(value.map((value) => (typeof value === 'boolean' ? value.toString() : value)));
};

const handleToggleAll = () => {
  if (isAllActive.value) {
    props.onUpdate([]);
  } else {
    props.onUpdate(
      props.options.map((option) =>
        isObject(option) ? (option.value as string | number) : option,
      ),
    );
  }
};
</script>

<template>
  <Stack :gap="0.5">
    <Checkbox
      v-if="props.options.length > 0"
      id="toggle-all"
      name="toggle-all"
      :size="size"
      :value="isAllActive"
      :isIndeterminate="isIndeterminate"
      :onUpdate="handleToggleAll"
    >
      {{ t('select-all') }}
    </Checkbox>

    <AntCheckboxGroup
      :id="id"
      class="checkbox"
      :name="name"
      :class="classNames({ size })"
      :value="value || undefined"
      :options="options"
      :onUpdate:value="handleUpdate"
    />
  </Stack>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/Checkbox/Checkbox.scss';
</style>
