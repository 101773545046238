import { UseMapbox } from '@libero/mapbox/hooks/useMapbox';

interface UseZoom {
  handleIncrease: () => void;
  handleDecrease: () => void;
}

export const useZoom = (mapbox: UseMapbox): UseZoom => {
  const handleIncrease = () => mapbox.map.zoomIn();
  const handleDecrease = () => mapbox.map.zoomOut();

  return {
    handleIncrease,
    handleDecrease,
  };
};
