<script setup lang="ts">
import { MapPinIcon, MinusIcon, PlusIcon } from '@heroicons/vue/24/solid';
import { publicContainerApi } from '@libero/api-client/public-container/api';
import { publicTicketApi } from '@libero/api-client/public-ticket/api';
import MapDropdown from '@libero/citizen-report/modules/map/components/MapDropdown/MapDropdown.vue';
import { useMapContext } from '@libero/citizen-report/modules/map/hooks/useMap';
import { useGeolocation } from '@libero/hooks/useGeolocation';
import Layers from '@libero/mapbox/components/Layers/Layers.vue';
import Legend from '@libero/mapbox/components/Legend/Legend.vue';
import { MapInstance } from '@libero/mapbox/components/Map/Map.types';
import Map from '@libero/mapbox/components/Map/Map.vue';
import { useClusterLayer } from '@libero/mapbox/hooks/layers/useClusterLayer';
import { useGeolocationLayer } from '@libero/mapbox/hooks/layers/useGeolocationLayer';
import { useLabelLayer } from '@libero/mapbox/hooks/layers/useLabelLayer';
import { useLineLayer } from '@libero/mapbox/hooks/layers/useLineLayer';
import { usePointLayer } from '@libero/mapbox/hooks/layers/usePointLayer';
import { usePolygonLayer } from '@libero/mapbox/hooks/layers/usePolygonLayer';
import { useGeoJsonSource } from '@libero/mapbox/hooks/sources/useGeoJsonSource';
import { useGeolocationSource } from '@libero/mapbox/hooks/sources/useGeolocationSource';
import { useLayers } from '@libero/mapbox/hooks/tools/useLayers';
import { useMarked } from '@libero/mapbox/hooks/tools/useMarked';
import { usePopup } from '@libero/mapbox/hooks/tools/usePopup';
import { useStyle } from '@libero/mapbox/hooks/tools/useStyle';
import { useZoom } from '@libero/mapbox/hooks/tools/useZoom';
import { useMapbox } from '@libero/mapbox/hooks/useMapbox';
import Button from '@libero/ui-framework/Button/Button.vue';
import ButtonGroup from '@libero/ui-framework/ButtonGroup/ButtonGroup.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Tooltip from '@libero/ui-framework/Tooltip/Tooltip.vue';
import { useQuery } from '@tanstack/vue-query';
import { uniqBy } from 'lodash';
import { onMounted } from 'vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const reference = ref<MapInstance>();
const map = useMapContext();
const mapbox = useMapbox(reference, true);

const themeId = computed(() => map.themeId);
const isContainer = computed(() => !!map.themeId);

const { data: geoJson, isLoading } = useQuery({
  queryKey: ['map', themeId],
  queryFn: () => {
    if (themeId.value) {
      return publicContainerApi.map({ filter: { themes: themeId.value } });
    } else {
      return publicTicketApi.map();
    }
  },
});

const zoom = useZoom(mapbox);
const style = useStyle(mapbox);
const layers = useLayers(mapbox);
const geoLocation = useGeolocation();

const geoLocationSource = useGeolocationSource(mapbox);
const geoJsonSource = useGeoJsonSource(mapbox, geoJson);
const popup = usePopup(mapbox);
const marked = useMarked(mapbox, isContainer);

useClusterLayer(mapbox, geoJsonSource);
usePointLayer(mapbox, geoJsonSource);
usePolygonLayer(mapbox, geoJsonSource);
useLineLayer(mapbox, geoJsonSource);
useLabelLayer(mapbox, geoJsonSource);
useGeolocationLayer(mapbox, geoLocationSource);

const legendItems = computed(() =>
  uniqBy(
    geoJson.value?.features.map((feature) => ({
      name: feature.properties.label,
      color: feature.properties.color,
    })),
    (item) => item.name,
  ).sort((a, b) => a.name.localeCompare(b.name)),
);

onMounted(() => {
  map.onCoordinatesUpdate(geoLocationSource.handleCoordinates);
  marked.onAdd(map.addContainer);
  marked.onRemove(map.removeContainer);
  map.onRemoveContainer(marked.handleRemove);
  map.onToggle(marked.toggle);
  geoLocation.onResult(geoLocationSource.handleCoordinates);
});
</script>

<template>
  <Map id="map" ref="reference" :isLoading="geoLocation.isLoading || isLoading" minHeight="20rem">
    <template #bottom-left-actions>
      <Legend v-if="legendItems" :items="legendItems" isStatic />
    </template>

    <template #bottom-right-actions>
      <Stack :gap="0.5">
        <ButtonGroup isVertical>
          <Tooltip :content="t('map.zoom-in')">
            <Button size="sm" appearance="select" :onClick="zoom.handleIncrease">
              <template #icon>
                <PlusIcon />
              </template>
            </Button>
          </Tooltip>

          <Tooltip :content="t('map.zoom-out')">
            <Button size="sm" appearance="select" :onClick="zoom.handleDecrease">
              <template #icon>
                <MinusIcon />
              </template>
            </Button>
          </Tooltip>
        </ButtonGroup>

        <Tooltip :content="t('map.my-location')">
          <Button size="sm" appearance="select" :onClick="geoLocation.getLocation">
            <template #icon>
              <MapPinIcon />
            </template>
          </Button>
        </Tooltip>

        <Layers v-bind="{ ...style, ...layers }" />
      </Stack>
    </template>

    <template v-if="!map.themeId" #popup>
      <MapDropdown v-if="popup.feature" :feature="popup.feature" :onClose="popup.handleClose" />
    </template>
  </Map>
</template>
