<script setup lang="ts">
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/solid';
import { Color } from '@libero/types/Color';
import Button from '@libero/ui-framework/Button/Button.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Dropdown from '@libero/ui-framework/Dropdown/Dropdown.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { LegendItem } from './Legend.types';

interface Props {
  items: LegendItem[];
  isStatic?: boolean;
}

const props = defineProps<Props>();

const { t, te } = useI18n();

const isActive = ref(false);

const toggleActive = () => {
  isActive.value = !isActive.value;
};

const getBorderColor = (item: LegendItem) => {
  if (item.count === 0) {
    return Color.gray800;
  } else {
    return Color.whiteFull;
  }
};

const sortedItems = computed(() =>
  [...props.items].sort((a) => {
    if (a.count && a.count > 1) return 1;
    if (a.count === 0) return 2;
    return -1;
  }),
);
</script>

<template>
  <template v-if="sortedItems.length > 0">
    <Stack v-if="isStatic" :gap="0.25" class="legend-content is-static">
      <Cluster
        v-for="item in sortedItems"
        :key="item.name"
        :gap="0.5"
        class="legend-item"
        alignItems="center"
      >
        <span
          class="legend-item-color"
          :style="{ backgroundColor: item.color, borderColor: getBorderColor(item) }"
        >
          {{ item.count && item.count > 1 ? '2' : '' }}
        </span>

        <Typography isDark>{{ item.name }}</Typography>
      </Cluster>
    </Stack>

    <Dropdown v-else placement="top-start" isPersistent>
      <template #trigger>
        <Button size="sm" appearance="select" :isActive="isActive" :onClick="toggleActive">
          {{ te('legend.legend') ? t('legend.legend') : t('legend') }}

          <template #icon-right>
            <ChevronDownIcon v-if="isActive" />
            <ChevronUpIcon v-else />
          </template>
        </Button>
      </template>

      <template #content>
        <Stack :gap="0.25" class="legend-content">
          <Cluster
            v-for="item in sortedItems"
            :key="item.color"
            :gap="0.5"
            class="legend-item"
            alignItems="center"
          >
            <span
              class="legend-item-color"
              :style="{
                backgroundColor: item.color,
                borderColor: getBorderColor(item),
              }"
            >
              {{ item.count && item.count > 1 ? '2' : '' }}
            </span>

            <Typography isDark>{{ item.name }}</Typography>
          </Cluster>
        </Stack>
      </template>
    </Dropdown>
  </template>
</template>

<style lang="scss" scoped>
@import '@libero/mapbox/components/Legend/Legend.scss';
</style>
