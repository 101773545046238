<script lang="ts" setup>
import { ChatBubbleBottomCenterTextIcon, ChevronRightIcon } from '@heroicons/vue/24/solid';
import { MapProperties } from '@libero/api-client/map/types';
import { PublicTicket } from '@libero/api-client/public-ticket/types';
import TicketStatus from '@libero/organisms/TicketStatus/TicketStatus.vue';
import { Color } from '@libero/types/Color';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import DropdownAction from '@libero/ui-framework/Dropdown/DropdownAction.vue';
import Icon from '@libero/ui-framework/Icon/Icon.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { computed } from 'vue';

interface Props {
  properties: MapProperties;
  ticket: PublicTicket;
}

const props = defineProps<Props>();

const color = computed(() => {
  switch (props.ticket.ticket_status) {
    case TicketStatus.Completed:
      return Color.successBase;
    default:
      return Color.warningBase;
  }
});
</script>

<template>
  <DropdownAction
    spacing="lg"
    hasBorder
    :to="{
      name: 'report.show',
      params: {
        containerId: properties.id,
        id: ticket.id,
      },
    }"
  >
    <Cluster alignItems="center" justifyContent="space-between" width="100%">
      <Stack :gap="0.25">
        <Typography size="lg" bold :color="color">
          {{ ticket.ticket_status.name }}
        </Typography>

        <Cluster alignItems="center">
          <Icon size="lg">
            <ChatBubbleBottomCenterTextIcon />
          </Icon>

          <Typography size="lg" truncated maxWidth="15rem">
            {{ ticket.ticket_subject?.name || '-' }}
          </Typography>
        </Cluster>
      </Stack>

      <Icon size="xl">
        <ChevronRightIcon />
      </Icon>
    </Cluster>
  </DropdownAction>
</template>
