import { MapProperties } from '@libero/api-client/map/types';
import { MapboxCursor, MapboxMode, UseMapbox } from '@libero/mapbox/hooks/useMapbox';
import { ALL_ACTIVE_CONTAINER_LAYERS, ALL_CONTAINER_LAYERS } from '@libero/mapbox/types/layers';
import { Geometry } from '@libero/types/Geometry';
import { Feature } from 'geojson';
import Mapbox, { LngLatLike, MapLayerMouseEvent, MapLayerTouchEvent, Popup } from 'mapbox-gl';
import { computed, nextTick, reactive, ref, watch } from 'vue';

export interface UsePopup {
  isOpen: boolean;
  feature?: Feature<Geometry, MapProperties>;
  distance?: number;
  handleClose: () => void;
  handleOpen: (
    item: Feature<Geometry, MapProperties>,
    lngLat: LngLatLike,
    distance?: number,
    onClose?: () => void,
  ) => void;
}

export const usePopup = (mapbox: UseMapbox, filterIds?: string[]): UsePopup => {
  const instance = ref<Popup>(new Mapbox.Popup({ closeOnClick: false }));
  const isOpen = ref(false);
  const closeCallback = ref<() => void>();

  const feature = ref<Feature<Geometry, MapProperties>>();
  const distance = ref<number>();

  const isPointerMode = computed(() => mapbox.mode === MapboxMode.Pointer);
  const isDrawingMode = computed(() => mapbox.mode === MapboxMode.Drawing);

  const handleOpen = (
    item: Feature<Geometry, MapProperties>,
    lngLat: LngLatLike,
    meters?: number,
    onClose?: () => void,
  ) => {
    if (!mapbox.instance?.popup) return;
    if (isOpen.value) handleClose();

    closeCallback.value = onClose;

    instance.value?.remove();
    instance.value = instance.value
      .setLngLat(lngLat)
      .setDOMContent(mapbox.instance.popup)
      .addTo(mapbox.map);

    instance.value?.once('close', handleClose);

    if (isDrawingMode.value || item.properties.id) {
      nextTick(() => {
        feature.value = item;
        distance.value = meters;
        isOpen.value = true;
      });
    }
  };

  const handleClose = () => {
    instance.value?.remove();
    closeCallback.value?.();

    isOpen.value = false;
    feature.value = undefined;
  };

  const handleMouseEnter = () => {
    mapbox.setCursor(MapboxCursor.Pointer);
  };

  const handleMouseLeave = () => {
    mapbox.resetCursor();
  };

  const handleMove = () => {
    instance.value?.remove();
  };

  const handleClick = (event: MapLayerTouchEvent | MapLayerMouseEvent) => {
    if (!mapbox.map || !event.features?.length) return;

    const [feature] = event.features as unknown as Feature<Geometry, MapProperties>[];
    if (filterIds?.includes(feature.properties.id)) return;

    if (mapbox.mode === MapboxMode.Pointer) {
      handleOpen(feature, event.lngLat);
    }
  };

  mapbox.onStyleLoad(() => {
    mapbox.map
      .off('click', ALL_CONTAINER_LAYERS, handleClick)
      .on('click', ALL_CONTAINER_LAYERS, handleClick);

    mapbox.map
      .off('touchstart', ALL_CONTAINER_LAYERS, handleClick)
      .on('touchstart', ALL_CONTAINER_LAYERS, handleClick);

    mapbox.map.off('move', handleMove).on('move', handleMove);

    mapbox.map
      .off('mouseenter', ALL_CONTAINER_LAYERS, handleMouseEnter)
      .on('mouseenter', ALL_CONTAINER_LAYERS, handleMouseEnter);

    mapbox.map
      .off('mouseleave', ALL_CONTAINER_LAYERS, handleMouseLeave)
      .on('mouseleave', ALL_CONTAINER_LAYERS, handleMouseLeave);

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') instance.value?.remove();
    });
  });

  watch(isPointerMode, () => {
    if (!isPointerMode.value) {
      ALL_ACTIVE_CONTAINER_LAYERS.forEach((layerName) => {
        const filter = mapbox.map.getFilter(layerName);
        if (!filter) return;

        filter[1] = ['in', 'id', ''];

        mapbox.map.setFilter(layerName, filter);
      });
    }
  });

  return reactive({
    isOpen,
    feature,
    distance,
    handleOpen,
    handleClose,
  });
};
