import { isNull, isUndefined } from 'lodash';
import { Ref } from 'vue';
import { ref } from 'vue';

type UseLocalStorage<T> = [Ref<T>, (newValue?: T) => void];

const getStorageValue = <T>(key: string): T | undefined => {
  const value = localStorage.getItem(key);

  if (value === null) {
    return undefined;
  }

  try {
    return JSON.parse(value);
  } catch {
    return value as T;
  }
};

export function useLocalStorage<T>(key: string): UseLocalStorage<T | undefined>;
export function useLocalStorage<T>(key: string, defaultValue: T | undefined): UseLocalStorage<T>;

export function useLocalStorage<T>(key: string, defaultValue?: T): UseLocalStorage<T> {
  const value = ref(getStorageValue<T>(key) || defaultValue) as Ref<T | undefined>;

  const setValue = (newValue?: T) => {
    if (!isUndefined(newValue) && !isNull(newValue)) {
      value.value = newValue;

      const isString = typeof newValue === 'string';
      const parsedValue = isString ? newValue : JSON.stringify(newValue);

      localStorage.setItem(key, parsedValue);
    } else {
      value.value = defaultValue;

      localStorage.removeItem(key);
    }
  };

  return [value as Ref<T>, setValue];
}
