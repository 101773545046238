import { debounce } from 'lodash';
import { onMounted, onUnmounted, Ref, watch } from 'vue';

export const useMutationObserver = (
  reference: Ref<HTMLElement | undefined>,
  onChange: () => void,
): void => {
  const mutationObserver = new MutationObserver(debounce(onChange, 50));

  const register = () => {
    if (reference.value) {
      mutationObserver.observe(reference.value, { childList: true, subtree: true });
    }
  };

  const unregister = () => {
    mutationObserver.disconnect();
  };

  watch(reference, () => {
    if (reference.value) {
      register();
    } else {
      unregister();
    }
  });

  onMounted(register);
  onUnmounted(unregister);
};
