<script setup lang="ts">
import { useMap } from '@libero/citizen-report/modules/map/hooks/useMap';
import Map from '@libero/citizen-report/modules/map/views/segments/Map.vue';
import { useResizeObserver } from '@libero/hooks/useResizeObserver';
import Title from '@libero/organisms/Title/Title.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouterView } from 'vue-router';

const { t } = useI18n();
useMap();

const app = ref<HTMLElement>();
const hasGlobalMap = ref(false);

const handleResize = () => {
  const width = app.value?.clientWidth || 0;
  hasGlobalMap.value = width >= 840;
};

useResizeObserver(app, handleResize);
</script>

<template>
  <div ref="app" class="app-layout">
    <div class="app-layout-main">
      <div class="app-layout-header">
        <Title size="sm" :title="t('citizen-report.title')" />
      </div>

      <div class="app-layout-content">
        <RouterView :hasInlineMap="!hasGlobalMap" />
      </div>
    </div>

    <div v-if="hasGlobalMap" class="app-layout-map">
      <Map />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/citizen-report/modules/app/views/layouts/AppLayout/AppLayout.scss';
</style>
