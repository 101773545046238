import { UseGeolocationSource } from '@libero/mapbox/hooks/sources/useGeolocationSource';
import { UseMapbox } from '@libero/mapbox/hooks/useMapbox';
import { GEOLOCATION_LAYER } from '@libero/mapbox/types/layers';
import { GEOLOCATION_SOURCE } from '@libero/mapbox/types/sources';
import pinError from '@libero/theme/icons/map/pin-error.png';

export const useGeolocationLayer = (
  mapbox: UseMapbox,
  geoLocationSource: UseGeolocationSource,
): void => {
  const registerLayers = () => {
    mapbox.map.loadImage(pinError, (error, image) => {
      if (error) throw error;
      if (!image) return;

      mapbox.map.addImage('pin-error', image);

      mapbox.map.addLayer({
        id: GEOLOCATION_LAYER,
        type: 'symbol',
        source: GEOLOCATION_SOURCE,
        layout: {
          'icon-image': 'pin-error',
          'icon-size': 0.5,
        },
      });
    });
  };

  geoLocationSource.onLoad(registerLayers);
};
